import React, { useEffect, useState } from 'react';
import { LineContext } from 'components/context';
import UsersBindingInputPage from 'pages/users/binding/input';
import { fetchAndSaveAuthToken } from 'services/user.service';
import { setupLiff } from 'src/lib/liff';
import { t } from 'src/i18n/config';
import { setAuth } from 'src/services/utils';
export default function Home(props) {
  const [message, setMessage] = useState({});
  const [token, setToken] = useState();
  useEffect(() => {
    setupLiff()
      .then(async (res) => {
        setMessage(res);
        const { userId, liffId } = res;
        if (userId && liffId) {
          const t = await fetchAndSaveAuthToken({ userId, liffId });
          setAuth({ token: t, liffId, userId });
          setToken(t);
          return;
        }
        alert(
          `${t('userNotFound')}${userId}, ${liffId} ${window.location.href}`
        );
      })
      .catch(async (err) => {
        setMessage(err);
      });
  }, []);
  if (token) {
    return (
      <LineContext.Provider value={message}>
        <UsersBindingInputPage {...props} {...message}></UsersBindingInputPage>
      </LineContext.Provider>
    );
  }
  return null;
}
